'use strict';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.css';
import '../style/fonts/Raleway-Regular.ttf';
import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';
import { BrowserRouter, Route, Switch, IndexRoute, Redirect } from 'react-router-dom';
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import pathToRegexp from 'path-to-regexp';

import App from './containers/app';
import Reviews from './containers/reviews';
import ReviewsDetail from './containers/reviews_detail';
import OtherReviews from './containers/other_reviews';
import OtherReviewsDetail from './containers/other_review_detail';
import Articles from './containers/articles';
import ArticleDetail from './containers/article_detail';
import News from './containers/news';
import NewsDetail from './containers/news_detail';
import Mainpage from './components/mainpage';
import DemoReviews from './containers/demo_reviews';
import DetailedDemoReview from './containers/detailed_demo_review';
import Videos from './containers/videos';
import ImpTV from './containers/imp_tv';
import Profiles from './containers/profiles';
import Rauta from './containers/rauta';
import DTP from './containers/dtp';
import Playlists from './containers/playlists';
import DetailedPlaylist from './containers/playlist_detail';
import Pages from './containers/pages';
import PageDetail from './containers/page_detail';
import Search from './containers/search';
import SpecialSearch from './containers/special_search';
import AddNews from './containers/add_news';
import AddGigs from './containers/add_gigs';
import AllGigs from './containers/all_gigs';
import AllReleases from './containers/all_releases';

/*
var addScript = document.createElement('script');
addScript.setAttribute('src', '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
document.body.appendChild(addScript);
*/
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fi';

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD.MM.YYYY';

const RedirectWithParams = ({ exact, from, push, to }) => {
    const pathTo = pathToRegexp.compile(to);
    return (
        <Route exact={exact} path={from} component={({ match: { params } }) => (
            <Redirect to={pathTo(params)} push={push} />
         )} />
    );
}

ReactDOM.render(
    <BrowserRouter>
        <CookiesProvider>
            <App>
                <Switch>
                    <Route exact path="/" component={Mainpage}/>
                    <Route exact path="/reviews/:page" component={Reviews}/>
                    <Route path="/reviews/:page/:id" component={ReviewsDetail}/>
                    <Route exact path="/otherreviews/:page" component={OtherReviews}/>
                    <Route path="/otherreviews/:page/:id" component={OtherReviewsDetail}/>
                    <Route exact path="/articles/:page" component={Articles}/>
                    <Route path="/articles/:page/:id" component={ArticleDetail}/>
                    <Route exact path="/news/:page" component={News}/>
                    <Route path="/news/:page/:id" component={NewsDetail}/>
                    <Route exact path="/demos/:page" component={DemoReviews}/>
                    <Route path="/demos/:page/:id" component={DetailedDemoReview}/>
                    <Route path="/videos/:page" component={Videos}/>
                    <Route exact path="/imptv/:page" component={ImpTV}/>
                    <Route path="/imptv/:page/:id" component={ImpTV}/>
                    <Route path="/rauta/:page" component={Rauta}/>
                    <Route exact path="/dtp" component={DTP}/>
                    <Route exact path="/playlists/:page" component={Playlists}/>
                    <Route path="/playlists/:page/:id" component={DetailedPlaylist}/>
                    <Route path="/search/:text" component={Search}/>        
                    <Route path="/search_with_parameter/:table/:column/:text" component={SpecialSearch}/>            
                    <Route exact path="/pages/:page" component={Pages}/>
                    <Route path="/pages/:page/:id" component={PageDetail}/>
                    <Route exact path="/profiles" component={Profiles}/>
                    <Route path="/admin/news" component={AddNews}/>
                    <Route path="/admin/gigs" component={AddGigs}/>
                    <Route exact path="/all_gigs" component={AllGigs}/>
                    <Route exact path="/all_releases" component={AllReleases}/>
                    <Redirect exact from="/fp" to="/"/>
                    <RedirectWithParams exact from="/albums/:id" to="/reviews/1/:id"/>
                    <Redirect exact from="/albums" to="/reviews/1"/>
                    <Redirect exact from="/interviews" to="/articles/1"/>
                    <RedirectWithParams exact from="/interviews/:id" to="/articles/1/:id"/>
                    <Redirect exact from="/books" to="/otherreviews/1"/>
                    <RedirectWithParams exact from="/books/:id" to="/otherreviews/1/:id"/>
                    <Redirect exact from="/news" to="/news/1"/>
                    <RedirectWithParams exact from="/news/:id" to="/news/1/:id"/>
                    <Redirect exact from="/demos" to="/demos/1"/>
                    <RedirectWithParams exact from="/demos/:id" to="/demos/1/:id"/>
                    <RedirectWithParams exact from="/page/:id" to="/pages/1/:id"/>
                    <Redirect exact from="/videos" to="/videos/1"/>
                    <Redirect exact from="/releases" to="/"/>
                    <Redirect exact from="/gigs" to="/"/>
                </Switch>
            </App>
        </CookiesProvider>
    </BrowserRouter>,
    document.getElementById('app-container')
);
