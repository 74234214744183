'use strict';
import '../style/main.css';
import React from 'react';
import Moment from 'react-moment';
import FontAwesome from 'react-fontawesome';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ReactImageFallback from 'react-image-fallback';
import { Link } from 'react-router-dom';

import { HashLink as HLink } from 'react-router-hash-link';
import { any, compose, concat, curry, flip, groupBy, map, merge, prop, replace, split, join, toLower, toPairs, mergeAll, remove, objOf, values, union, match, isEmpty, isNil, slice, lastIndexOf } from 'ramda';
import he from 'he';
import 'moment/locale/fi';
import axios from 'axios';

import RadialProgress from 'components/radial_progress';


export const sortFirstByMatch = (search, key, items) => {
    const groupByItem = groupBy(item => {
        const searchKey = item[key];

        return toLower(searchKey).startsWith(toLower(search)) == true ? "exact" : "aux";
    });
    const itemsGrouped = groupByItem(items);
    const exact = values(itemsGrouped['exact']);
    const aux = values(itemsGrouped['aux']);
    const results = union(exact, aux);
    return results;
}

export const sortFirstByLike = (search, key, items) => {
    const groupByItem = groupBy(item => {
        const searchKey = item[key];
        var regex = new RegExp("" +  toLower(search));
        const result = match(regex, toLower(searchKey));
        return  isEmpty(result) ? "aux" : "like";
    });

    const itemsGrouped = groupByItem(items);
    const exact = values(itemsGrouped['like']);
    const aux = values(itemsGrouped['aux']);
    const results = union(exact, aux);
    return results;
    
}


export const getEventValue = event => event.target.value;
export const updateFromEventValue = curry((update, key) => e => update(objOf(key, getEventValue(e))) );

export const isUndefinedOrEmpty = (value) => {
    if (isNil(value) || isEmpty(value)) {
        return true;
    }
    return false;
}

export const genMetaTags = (url, imageName) => {
    const server = serverAddress();
    const ogUrl = server + url;
    const ogImage = server + imageName;
    return(
        <MetaTags>
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content={ogUrl} />
        </MetaTags>
    )
}

export const imageWithLink = (imageFileName, linkName, className, fallbackImageName='images/default_review.png') => {
    return (
        goTo(linkName, 
            <ReactImageFallback
                src={imageFileName}
                fallbackImage={fallbackImageName}
                className={className}/>
        )
    );
};

export const serverAddress = () => {
    const protocol = ('https:' == document.location.protocol ? 'https' : 'http');

    //return "http://localhost";
    //return protocol + "://www.arsikoutaniemi.com";
    if (document.location.hostname == "localhost" || document.location.hostname == "127.0.0.1") {
        return protocol + "://" + "www.impe.fi";        
    }
    return protocol + "://" + document.location.hostname;
};

export const improveAdsLink = (z) => {
    const protocol = ('https:' == document.location.protocol ? 'https' : 'http');
    const uri = protocol + '://adsby.improveads.fi/imp?z=' + z +'&rnd=' + new String (Math.random()).substring (2, 11);
    const script = '<script src=' + uri + '></script>';    
    return script;
};

export const tradeDoublerAdsLink = () => {
    const protocol = ('https:' == document.location.protocol ? 'https' : 'http');
    const uri = protocol + '://impfi.tradedoubler.com/imp?type(js)pool(514474)a(519796)'  + new String (Math.random()).substring (2, 11);
    const script = '<script type="text/javascript" charset="ISO-8859-1" src=' + uri + '></script>';    
    return script;   
}

export const taboolaLink = ()  => {
    const script = `
    <script type="text/javascript">
        window._taboola = window._taboola || [];
        _taboola.push({article:'auto'});
        !function (e, f, u, i) {
        if (!document.getElementById(i)){
            e.async = 1;
            e.src = u;
            e.id = i;
            f.parentNode.insertBefore(e, f);
        }
        }(document.createElement('script'),
        document.getElementsByTagName('script')[0],
        '//cdn.taboola.com/libtrc/embimedia-imperiumi/loader.js',
        'tb_loader_script');
        if(window.performance && typeof window.performance.mark == 'function')
        {window.performance.mark('tbl_ic');}
    </script>

    <script type="text/javascript">;
        window._taboola = window._taboola || [];
        _taboola.push({
            mode: 'thumbnails-a',
            container: 'taboola-below-article-thumbnails',
            placement: 'Below Article Thumbnails',
            target_type: 'mix'
        });
        </script> 
        <script type="text/javascript">
            window._taboola = window._taboola || [];
            _taboola.push({flush: true});
        </script>`;
    return script;    
}


export const RightNavButton = props =>  {
    const {className, style, onClick} = props;
    return (
        <button className="click-button-right" onClick={onClick}><FontAwesome name='chevron-right'/></button>
    );
};

export const LeftNavButton = props => {
    const {className, style, onClick} = props;
    return (
        <button className="click-button-left" onClick={onClick}><FontAwesome name='chevron-left'/></button>
    );
}

export const spotifyLink = band => {

    return concat('https://play.spotify.com/album/')(band);    
}

export const cdonLink = band => {
    return concat('https://clk.tradedoubler.com/click?p=345&a=519796&url=https://www.cdon.fi/musiikki/')(band);        
}

export const xLink = band => {
    return concat('https://www.levykauppax.fi/search/?from=imp&?f=all&q=')(band);
}

export const deezerLink = band => {
    return concat('https://www.deezer.com/album/')(band);
}

export const rockshirtsLink = band => {
    return concat('https://rockshirts.fi/fi/search?affiliates=9%3Fsearch%3Forderby%3Dposition&orderway=desc&search_query=')(band);
}


const spotifyUrl = (link, toolTipState, toggle) => {
    return (
        <span className="share-image">
            <a  onClick={toggle} id="SpotifyToolTip"><FontAwesome name='spotify' size='4x'/></a>
            <Popover placement="right" isOpen={toolTipState} target="SpotifyToolTip" toggle={toggle} className="pop-player">
                <PopoverHeader>Spotify-soitin</PopoverHeader>
                <PopoverBody>
                    {spotifyEmbed(link)}
                </PopoverBody>
            </Popover>
        </span>
    );
}

export const spotify = (link, toolTipState, toggle) => {
    return link ? spotifyUrl(link, toolTipState, toggle) : undefined;
}

const deezerUrl = (link, toolTipState, toggle) => {

    return (
        <span className="share-image">
            <a  onClick={toggle} id="DeezerToolTip">
            <ReactImageFallback
                src="/images/icons/deezer.jpg"
                fallbackImage="/images/default_review.png"
                className="iconReview"
            /> 
            </a>
            <Popover placement="right" isOpen={toolTipState} target="DeezerToolTip" toggle={toggle} className="pop-player">
                <PopoverHeader>Deezer-soitin</PopoverHeader>
                <PopoverBody>
                    {deezerEmbed(link)}
                </PopoverBody>
            </Popover>
        </span>
    );
}

export const deezer = (link, toolTipState, toggle) => {
    return link ? deezerUrl(link, toolTipState, toggle) : undefined;
}

export const deezerEmbed = (id) => {
    if (id == undefined || id == "") {
        return undefined;
    }
    const url = "https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=true&width=250&height=350&color=336b87&layout=dark&size=medium&type=album&id=" + id + "&app_id=1";
    return <iframe src={url} allowtransparency="true" width="300" height="300" frameborder="0"></iframe>
}

const cdonUrl = (link, updateUrl) => {
    const cdon = updateUrl ? cdonLink(link) : link;
    return (linkTo(cdon,
        <ReactImageFallback
            src="/images/icons/cdon_logo.jpg"
            fallbackImage="/images/default_review.png"
            className="iconReview"
            />
        )
    );
}

export const cdon = (link, updateUrl) => {
    return cdonUrl(link, updateUrl);
}

const axUrl = (link) => {
    return (linkTo(xLink(link), 
        <ReactImageFallback
            src="/images/icons/icon_big_levykauppax.png"
            fallbackImage="/images/default_review.png"
            className="iconReview"
            />
        )
    );                                      
}

export const levykauppax = (link) => {
    return axUrl(link);
}

const rockShirtsUrl = (link) => {
    return (linkTo(rockshirtsLink(link),
        <ReactImageFallback
        src="/images/icons/icon_rockshirts.jpg"
        fallbackImage="/images/default_review.png"
        className="iconReview"
        />
        )   
    );
}

export const rockshirts = (link) => {
    return rockShirtsUrl(link);
}

// Base URL concanated with wanted one
export const queryApiUrl = url => {
    return concat(serverAddress(), concat('/directus/api/1.1/tables/', url));
}

export const addApiUrl = (url) => {
    return concat(serverAddress(), concat('/directus/api/1.1/tables/', concat(url, '?&access_token=Y1t3IiYqYo0W8LpPLPxB02oJMQ9jede4&?activity_skip=1')));    
}

export const httpRequest = urlPath => {
    const request = new Request(urlPath, 
        {
            mode:"cors", 
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            })
        }
    );    
    return request;    
};

export const getTodayAsString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const todayAsString = year + '-' + month + '-' + date;
    return todayAsString;    
};

export const getYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    return year;
}

export const getStartThisYear = () => {
    const today = new Date();
    const year = today.getFullYear();

    const startThisYear = year + '-01-01';
    return startThisYear;
}

export const getStartSelectedYear = (year) => {
    const startThisYear = year + '-01-01';
    return startThisYear;
}

//FIX
export const getWeekAgoDate = () => {
    const beforedate = new Date();
    const priordate = new Date(new Date().setDate(beforedate.getDate()-7));
    const year = priordate.getFullYear();
    const month = priordate.getMonth() +1;
    const date = priordate.getDate();
    const todayAsString = year + '-' + month + '-' + date;
    return todayAsString;    
    
};

export const youtubePlayer = (id) => {
    if (id == undefined || id == "")
        return undefined;
    const url = "https://www.youtube.com/embed/" + id + "?ecver=2";
    return (
        <div className="youtube-player">
            <iframe src={url} allowfullscreen="yes" width="560" height="315" frameborder="0"/>
        </div>    
    );
}

export const spotifyEmbed = (id) => {
    if (id == undefined || id == "") {
        return undefined;
    }
    const url = "https://open.spotify.com/embed/album/" + id;
    return <iframe src={url} allowtransparency="true" width="300" height="300" frameborder="0"></iframe>
}

export const createMarkup = text => {
    return {__html: text};
};

export const createDangerous = (element, elementId) => {
    return (
        <div className={elementId} dangerouslySetInnerHTML={element}/>
    );
};

export const removeImages = (data) => {
    const part0 = data.search(/<!--carousel-->/g);
    const part1 = data.search(/<!--end carousel-->/g);

    const dataLeft = slice(0, part0, data);
    return dataLeft;

}

export const createCarousel = (data) => {

    const part0 = data.search(/<!--carousel-->/g);
    const part1 = data.search(/<!--end carousel-->/g);

    if (part0 > 0) {
        const picturesText = slice(part0, part1, data);
        const text = picturesText.replace(/<\/p>/g, "");
        const pictures = split("<p>", text);
        return pictures;
    }


    return undefined;
}

export const createRightSideReview = (performer, record, points) => {
    const performerText = he.decode(performer);
    const albumText = he.decode(record); 
    return ([
        <div>
            <div className="review__band">{performerText}</div>
            <div className="review__album">{albumText}</div>
            <div className="review__points">{scoreDisplayer(points)}</div>
        </div>
    ]);
};

export const paragraphRemoveImagesAndVideos = (text) => {

    
    const part0 = text.replace(/<div style="position:relative;height:0;padding-bottom:56.25%">.*<\/div>/g, "");
    const part1 = part0.replace(/<iframe.*<\/iframe>/g, "");
    const part2 = part1.replace(/<img.*\/>/g, "");
    const part3 = part2.replace(/<img.*<\/img>/g, "");
    return part3;
}

export const paragraphSkipImages = (text) => {
    const removedFirstImage = text.replace(/^<p.*><img.*\/><\/p>/g, "");
    return removedFirstImage;
/*
    const imageWithParagraphFirst = text.startsWith("<p><img"); 
    if (imageWithParagraphFirst == true) {
        const skipImg =  text.search("/>");
        currentText = text.substr(skipImg+2);
        if (currentText.startsWith("</p>")) {
            currentText = currentText.substr(4);
        } else {
            currentText = "<p>" + currentText;
        }
    } 
    return currentText;
    */
};

export const removeOldTags = (text) => {
    // log("Remove old tags");
    const part1 =  replace(/(\[chap\])/g, '<p>', text); 
    const part2 =  replace(/(\[img.\|.*?])/g, '', part1);
    return part2;
}

export const paragraphOrMaxLength = (text, max_length) => {
    var currentText = text;
    /*
    const imageWithParagraphFirst = text.startsWith("<p><img"); 
    if (imageWithParagraphFirst == true) {
        const skipImg =  text.search("/>");
        currentText = text.substr(skipImg+2);
        if (currentText.startsWith("</p>")) {
            currentText = currentText.substr(4);
        } else {
            currentText = "<p>" + currentText;
        }
    } 
    */
    const place_p = currentText.search("</p>");
    const place_br_or_p = place_p > max_length ? currentText.search("<br") :  place_p;
    const place = place_br_or_p > max_length || place_br_or_p < 0 ? max_length : place_br_or_p;
    const review_text = place == max_length ? currentText.substr(0, place) + "..." : currentText.substr(0, place);
    return review_text;
};

export const periodOrMaxLength = (text, max_length) => {
    const place_period = text.search(/\. |\.<|!|\?\s/);
    const place = place_period > max_length ? max_length : place_period;
    const review_text = place == max_length ? text.substr(0, place) + "..." : text.substr(0, place) + text[place_period];
    return review_text;    
}

export const periodOrMaxLengthOnlyStrings = (text, max_length) => {
    const place_period = text.search(/\.|!|\?\s/);
    const place = place_period > max_length ? max_length : place_period;
    const review_text = place == max_length ? text.substr(0, place) + "..." : text.substr(0, place) + text[place_period];
    return review_text;    
}

export const authorLink = (author) => {
     const profileLink =  "/profiles#" + he.encode(author);
     return (
         <HLink className="author-link" to={profileLink}><FontAwesome className="writer-icon" name='pencil-square-o'/>{author}</HLink>
     );
};

export const photographerLink = (photographer) => {
     const profileLink =  "/profiles#" + he.encode(photographer);
     return (
         <HLink className="author-link" to={profileLink}><FontAwesome className="camera-icon" name='camera'/>{photographer}</HLink>
     );
    
}

export const dateConverted = (oldDate) => {
    return (
        <Moment locale="fi">{oldDate}</Moment>
    )
}

export const dateDisplayer = (date) => {
    return (
        <div className="date-displayer">
            <FontAwesome className="date-icon" name='calendar-o'/>{date}
        </div>
    );
}

export const objectToGetParams = (object) => {
  return '?' + Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join('&');
}

export const facebook = (url, { quote, hashtag }) => {

  return 'https://www.facebook.com/sharer/sharer.php' + objectToGetParams({
    u: url,
    quote,
    hashtag,
  });
}

const scoreOutputter = (radius, step) => {
    if (radius > 359)
        radius = 360;
    const parts = radius  / step;
    const score = parts / 4;

    const mod = score % Math.trunc(score);
    var rating = score.toString();
    if (mod > 0.5) {
        rating = Math.round(score).toString() + "-"; 
    } else if (mod < 0.5 && mod != 0) {
        rating = Math.trunc(score).toString() + "+";
    } else if (mod == 0) {
        rating = Math.trunc(score).toString();
    } else {
        rating = Math.trunc(score).toString() + "½";
    }
    return rating;    
}

export const scoreDisplayer = (score, step) => {
    if (score == 0) {
        return  (
            <div>Ei arvosanaa</div>
        )
    }
    const mod = score % Math.trunc(score);
    var fraction = 0;
    var integer = 0;
    if (mod > 0.5) {
        integer = Math.trunc(score)
        fraction = 3;
    } else if (mod < 0.5 && mod != 0) {
        integer = Math.trunc(score)
        fraction = 1;
    } else if (mod == 0) {
        integer = Math.trunc(score)
        fraction = 0;
    } else {
        integer = Math.trunc(score)
        fraction = 2;
    }

    const radius = (integer * 4 + fraction) * step;
    const params ={
        'value' : radius,
        'formatText': scoreOutputter,
        'step' : step,
    }
    return (
        <RadialProgress ref="app" {...params} />
    )
 }

export const goTo = (url, object, click) => {
    if (!url) {

        return (
            <div>
                {object}
            </div>
        );
    }
    return (
        <Link to={url} onClick={click}>
                {object}
        </Link>        
    );
}

export const facebookLink = (url) => {
    return (
        <a href={url} target="_blank">
            <FontAwesome name='facebook-official' size='4x' className="iconReview"/>
        </a>
    )
};

export const twitterLink = (url) => {
    return (
        <a href={url} target="_blank">
            <FontAwesome name="twitter" size='2x' className="share"/>
        </a>
    )
};

export const bandLink = (url) => {
    return (
        <a href={url} target="_blank">
            <FontAwesome name='home' size='4x' className="iconReview"/>
        </a>
    )
    
};

export const linkTo = (url, object, classname) => {
    return (
        <span className={classname}>
            <a href={url} target="_blank">
                {object}
            </a>
        </span>
    )
};

export const pageLink = (pg, title) => {
    return (
        <p>
            <Link to={ pg }>{ title }</Link>
        </p>
    )
};


export const profileFacebookLink = (url) => {
    return (
        <a href={url} target="_blank" className="fa fa-fw fa-facebook"/>
    )
};

export const profileTwitterLink = (url) => {
    return (
        <a href={url} target="_blank" className="fa fa-fw fa-twitter"/>
    )
};

export const profileInstaLink = (url) => {
    return (
        <a href={url} target="_blank" className="fa fa-fw fa-instagram"/>
    )
};

export const profileLinkedInLink = (url) => {
    return (
        <a href={url} target="_blank" className="fa fa-fw fa-linkedin"/>
    )
};

export const profileHomepageLink = (url) => {
    return (
        <a href={url} target="_blank" className="fa fa-fw fa-home"/>
    )
};

export const parseFromPathName = (pathName) => {
    const p1 = slice(1, Infinity, pathName)
    const place = p1.indexOf('/');
    if (place != -1) {
        return slice(place, Infinity, p1);
    } else {
        return p1;
    }
}

export const stringToDate = (_date,_format,_delimiter) => {
    const formatLowerCase=_format.toLowerCase();
    const formatItems=formatLowerCase.split(_delimiter);
    const dateItems=_date.split(_delimiter);
    const monthIndex=formatItems.indexOf("mm");
    const dayIndex=formatItems.indexOf("dd");
    const yearIndex=formatItems.indexOf("yyyy");
    var month=parseInt(dateItems[monthIndex]);
    month-=1;
    const formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
    return formatedDate;
}

// log :: a -> a
export const log = a => { console.log(a); return a; };

export const todayCalender = (data) =>  {
    const day = <Moment format="DD">{data.date}</Moment>
    const month = <Moment format="MMMM">{data.date}</Moment> 
    return (
            <div className="calender">
                <div className="calender-text">
                    {day}
                </div>
                <div className="calender-month">
                    {month}
                </div>
            </div>    
    );
}

export const todayCalenderWithYear =  (date) => {
    const day = <Moment format="DD">{date}</Moment>
    const month = <Moment format="MMMM">{date}</Moment> 
    const year = <Moment format="YYYY">{date}</Moment>

    return (
            <div className="year-calender">
                <div className="year-calender-daymonth">
                    <div className="year-calender-text">
                        {day}
                    </div>
                    <div className="year-calender-month">
                        {month}
                    </div>
                </div>
                <div>
                    <div className="year-calender-year">
                        {year}
                    </div>
                </div>
            </div>    
    );
    
}


export const postHits = (api, hits) => {
    const data = {
        'hits': hits,
    }
    const urlPath = addApiUrl(api);
    // Make a request for a user with a given ID
    axios.patch(urlPath, data)
    .then(function (response) {
        log(response);
     })
    .catch(function (error) {
        log(error);
    });                  
};