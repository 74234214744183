'use strict';
import '../../style/admin.css';
import 'input-moment/dist/input-moment.css';
import React from 'react';
import {curry} from 'ramda';
import moment from 'moment';
import InputMoment from 'input-moment';
import axios from 'axios';
import Notification from '../components/notification';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types'
import { addApiUrl, httpRequest, log, getTodayAsString, updateFromEventValue } from '../general';
import ReCAPTCHA from "react-google-recaptcha";


class AddGigs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { date: '', time: '', venue: '', bands: '',  moreInfo: '', link:'', captcha: true, m: moment(), notice: false }; 
        this.releaseTimeout = undefined;

        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }
             
    componentWillMount() {
        window.scrollTo(0, 0);
    }   

    onChange(value) {
        if (value != undefined) {
            this.setState({captcha: false});
        }
    }

    handleChange(m) {
        this.setState({ m });
    };
    
    hideNotification() {
        //log('hideNotification');
        this.setState({notice: false});
    }

    showNotification() {
        //log('showNotificaiton');
        this.setState({notice: true});
    }

    componentWillUnmount() {
        clearTimeout(this.releaseTimeout);
    }    

    render() {

        const {date, time, venue, bands, moreInfo, link, m, notice} = this.state;
        const inputUpdate = updateFromEventValue(this.setState.bind(this));
        
        const captcha = <ReCAPTCHA ref="recaptcha" sitekey="6Lc4Tj4UAAAAAP-pNRoc3c_7PBXieQ84sO102RQA" onChange={this.onChange}/>
        const post = (e) => {
            /*log("Searching");*/
            e.preventDefault();
            // log(this.state);

            
            const data = {
                'published': 0,
                'date': m.toDate(),
                'venue' : venue,
                'bands': bands,
                'addinfo' : moreInfo,
                'link' : link,
                'time' :  m.format('LT'),
            }
            const urlPath = addApiUrl('imp_gigs/rows');
            const self = this;
            // Make a request for a user with a given ID
            axios.post(urlPath, data)
            .then(function (response) {
                // log(response);
                self.showNotification();
                self.releaseTimeout = setTimeout(() => self.hideNotification(), 2000);

             })
            .catch(function (error) {
                log("Error creating gig");
                log(error);
            });
                            
        };
        
        
        return (
            <div className="admin-page">
                <Form onSubmit={post}>
                    <FormGroup>
                        <Label>Päiväys</Label>
                        <Input type="datetime" value={m.format('L LTS')} />
                        <InputMoment
                            moment={m}
                            onChange={this.handleChange}
                            minStep={10} // default
                            hourStep={1} // default
                            prevMonthIcon="fa fa-arrow-left" // default
                            nextMonthIcon="fa fa-arrow-right" // default
                      />      
                    </FormGroup>
                    <FormGroup>
                        <Label>Keikkapaikka</Label>
                        <Input type="text" value={venue} onChange={inputUpdate('venue')} placeholder="Keikkapaikka, Kaupunki"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Esiintyjät</Label>
                        <Input type="text" value={bands} onChange={inputUpdate('bands')} placeholder="Yhtye1 / Yhtye2..."/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Lisäinfot</Label>
                        <Input type="text" value={moreInfo} onChange={inputUpdate('moreInfo')} placeholder="Lipunhinta, ikäraja..."/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Facebook-event</Label>
                        <Input  type="text" value={link} onChange={inputUpdate('link')} placeholder="FB event linkki"/>
                    </FormGroup>
    
                    <Button disabled={this.state.captcha} type="submit">Jätä keikkailmoitus</Button>
                    {captcha}
                </Form>
                <Notification show={notice} text="Keikkailmoituksesi on nyt tallennettu. KIITOS!" />
            </div>
        );
    }
}


AddGigs.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default AddGigs;
