'use strict';
import "../../style/other_review.css";
import React  from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import OtherReviewsDetailPage from '../components/other_review_detail_page';
import { httpRequest, queryApiUrl, log, postHits } from '../general';

class OtherReviewsDetail extends React.Component {
    constructor() {
        super();
        this.state = { review: undefined, fetched: false };
    }
    
    getReviewFromApiAsync() {
        const api = 'imp_booksmovies/rows/' + this.props.match.params.id + "/?";
        const urlPath = queryApiUrl(api);

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            const hits = response.data.data.hits + 1; 
            postHits(api, hits);
            self.setState({
                review: response.data.data,
                fetched: true,
             });
         })
        .catch(function (error) {
            log("Error fetching reviews");
            log(error);
            self.setState({review:undefined, fetched: true, error: true});
        });                    
    }

    componentWillMount() {
        this.getReviewFromApiAsync();
        window.scrollTo(0, 0);        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ( nextState.fetched == false ) {
            return false; 
        }
        
        return true;
    }

    render() {
        const {fetched, review} = this.state;
        if (fetched == false) {
            return null;
        }
        const art = "/images/bookcovers/" + review.coverart; 
        const review_image = "https://www.impe.fi" + art;
        const review_description = review.director + " / " + review.title + " -arvio (" + review.reviewer +")";
        return (
            <div className="other-review-detail">
                <Helmet>
                    <meta property="title" content={review.performer}/>
                    <meta property="description" content={review_description}/>
                    <meta property="twitter:title" content={review.performer}/>
                    <meta property="twitter:description" content={review_description}/>
                    <meta property="twitter:image" content={review_image}/>
                    <meta property="og:title" content={review.performer}/>
                    <meta property="og:description" content={review_description}/>
                    <meta property="og:image" content={review_image}/>
                </Helmet>

                <OtherReviewsDetailPage review={review} location={this.props.match}/>
            </div>
        );
    }
}

OtherReviewsDetail.contextTypes = {
    layoutState: PropTypes.object
};


OtherReviewsDetail.propTypes = {
    match: PropTypes.object
};

export default OtherReviewsDetail;
