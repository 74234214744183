
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types'

import $script from 'scriptjs';
import postscribe from 'postscribe';
import { log, createMarkup } from '../general';   

// This GlobalAdCodePromise is a simple promise that uses script.js to load a javascript file and then resolve.
const GlobalAdCodePromise = (script) => {
    return new Promise((resolve) => {
        setTimeout(() => {            
            $script(script, () => {
                resolve(window.annoyingAdProvider);
            });
        }, 500);
    }
)};

// If the ad provider base code uses document.write too, you can fix this with Postscribe too.
// The #ad-safe-container is a simple div I put somewhere in my HTML, the ad just needs a place to write to.
const DocumentWritingAdPromise = (script, elementId) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const element = "#" + elementId;
            postscribe(element, script, {
                done: () => {
                    resolve(true);
                }
            });
        }, 200);
    }
)};

class Ad extends React.Component {

    constructor(...options) {
        super(...options);
        this.releaseTimeout = undefined;
        this.state = {
            released: false
        };
    }

    render() {
        if (!this.state.released) {
            return <div className={this.props.elementId} />;
        }

        return (
            <div ref='adcontainer'></div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.released && !prevState.released) {
            let ad = this.getAdHtml();
            setImmediate(() => {
                // debug('Requesting ad render');

                const  element = ReactDOM.findDOMNode(this.refs.adcontainer);
                postscribe(element, ad, {done: () => {
                    // debug('Ad rendered');
                }});
            });
        }
    }

    getAdHtml() {
        const html = createMarkup(`window.annoyingSyncAdProvider.tag({ format: "square-ad", publication: "mysite"});`);
        let ad = (
            <div id='square-ad-from-provider'>
                <script type='text/javascript' dangerouslySetInnerHTML={html}></script>
            </div>
        );

        return ReactDOMServer.renderToStaticMarkup(ad);
    }

    loadAd() {
        DocumentWritingAdPromise(this.props.script, this.props.elementId).then(() => {  this.setState({released: true})});
    }

    componentDidMount() {
        this.releaseTimeout = setTimeout(() => this.loadAd(), 100);
    }

    componentWillUnmount() {
        if (this.op) {
            clearTimeout(this.releaseTimeout);
        }
    }
}

Ad.propTypes = {
  script: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
};

export default Ad;