'use strict';
import '../../style/admin.css';
import React from 'react';
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import moment from 'moment';
import InputMoment from 'input-moment';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types'
import axios from 'axios';
import { addApiUrl, httpRequest, log, getTodayAsString, updateFromEventValue } from '../general';
import ReCAPTCHA from "react-google-recaptcha";
import Notification from '../components/notification';


class AddNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = { m: moment(), author: '', topic: '',  text: '', captcha: true, notice: false }; // You can also pass a Quill Delta here
        this.releaseTimeout = undefined;

        this.onChange = this.onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.hideNotification = this.hideNotification.bind(this);
        this.showNotification = this.showNotification.bind(this);

    }
         
    componentWillMount() {
        window.scrollTo(0, 0);
    }    

    componentWillUnmount() {
        clearTimeout(this.releaseTimeout);
    }    


    onChange(value) {
        if (value != undefined) {
            this.setState({captcha: false});
        }
    }    
    
    handleChange(m) {
        this.setState({ m });
    };
    

    hideNotification() {
        // log('hideNotification');
        this.setState({notice: false});
    }

    showNotification() {
        // log('showNotificaiton');
        this.setState({notice: true});
    }

    handleChangeText(value) {
        this.setState({ text: value });
    }    
    

    render() {
        const {date, author, topic, text, notice, m} = this.state;              
        const quill = <ReactQuill theme="snow" value={text} onChange={this.handleChangeText}><div className="my-area"/></ReactQuill>      
        const inputUpdate = updateFromEventValue(this.setState.bind(this));        
        const captcha = <ReCAPTCHA ref="recaptcha" sitekey="6Lc4Tj4UAAAAAP-pNRoc3c_7PBXieQ84sO102RQA" onChange={this.onChange}/>
        const post = (e) => {
            /*log("Searching");*/
            e.preventDefault();
            // log(this.state);

            
            const data = {
                'published': 0,
                'date': m.toDate(),
                'author' : author,
                'topic': topic,
                'news' : text,
                'time' :  m.format('LT'),
                'standout' : 0,
                'hits' : 0,
                'so_image' : '',
                'timestamp' : m,
            }
            const urlPath = addApiUrl('imp_news/rows');
            const self = this;
            // Make a request for a user with a given ID
            // log(data);
            axios.post(urlPath, data)
            .then(function (response) {
                // log(response);
                self.showNotification();
                self.releaseTimeout = setTimeout(() => self.hideNotification(), 2000);

             })
            .catch(function (error) {
                log("Error creating news");
                log(error);
            });
                            
        };
        
        return (
            <div className="admin-page">
                <Form onSubmit={post}>
                    <FormGroup>
                        <Label>Päiväys</Label>
                        <Input type="datetime" value={m.format('L LTS')} />
                        <InputMoment
                            moment={m}
                            onChange={this.handleChange}
                            minStep={10} // default
                            hourStep={1} // default
                            prevMonthIcon="fa fa-arrow-left" // default
                            nextMonthIcon="fa fa-arrow-right" // default
                        />      
                    </FormGroup>                
                    <FormGroup>
                        <Label>Kirjoittaja</Label>
                        <Input type="text" value={author} onChange={inputUpdate('author')} name="author" id="author"/>
                        <FormText>Anna kirjoittaja</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Otsikko</Label>
                        <Input type="text" value={topic} onChange={inputUpdate('topic')} name="topic" id="topic"/>
                        <FormText>Anna otsikko</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Uutinen</Label>
                        {quill}                        
                    </FormGroup>

                    <Button disabled={this.state.captcha} type="submit">Jätä uutisvinkki</Button>
                    {captcha}
                </Form>
                <Notification show={notice} text="Uutinen on nyt tallennettu. KIITOS!" />
                
            </div>
        );
    }
}

AddNews.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default AddNews;
