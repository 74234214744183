"use strict";
import '../../style/other_review.css';
import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom'
import Dimensions from 'react-dimensions';
import { Card,  CardHeader, CardFooter, CardBody, Collapse, Button } from 'reactstrap';
import classnames from 'classnames';
import { log, scoreDisplayer, authorLink, facebookLink, bandLink, twitterLink, pageLink, linkTo, LeftNavButton, RightNavButton, createMarkup, createDangerous, spotifyLink, cdonLink, xLink, deezerLink, serverAddress, improveAdsLink} from '../general';
import ShareButtons from './share_buttons';
import PublishingInfo from './publishing_info';
import AdPlacer from './ad_placer';
import he from 'he';

class OtherReviewsDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }
    
    toggle() {
        // update state
        this.setState({
            'collapse' : !this.state.collapse,
        });        
        
    } 
    
    
    render() {
        const {location, review, containerWidth} = this.props;
        // log(location);
        if (this.props.review == undefined) {
            return (
                <div>
                    <h3>Arvostelua ei löytynyt</h3>
                </div>
            );
        }

        const ownurl = serverAddress() + location.pathname;
        const messageToShare = "#Imperiumi arvosteli " + this.props.review.director + " - " + this.props.review.title + ": ";
        const art = "/images/bookcovers/" + this.props.review.coverart; 
        const performerText = he.decode(this.props.review.director);
        const albumText = he.decode(this.props.review.title); 
        const header = () => {
            return (
                <div className="other-review-detail-head">
                    <div className="other-review-detail-head-info">
                        <div>
                            {albumText}
                        </div>
                        <div className="other-review-name">
                            {performerText}
                        </div>        
                        <div className="company">
                            {this.props.review.company}
                        </div>                    
                    </div>
                    <div className="other-review-score">
                        {scoreDisplayer(this.props.review.points, 9)}
                    </div>
                </div>                
            );
        };


        const authorText = authorLink(this.props.review.reviewer);
        const linkText = bandLink(this.props.review.link);
        const facebookText = undefined;
        const headerText = header(); 
        const facebookAppId = "undefined";
         
        
        const moreInfo = this.props.review.description ? this.props.review.description.split("\n").map((item)=>item.trim()) : undefined;
        var moreInfoList
        if (moreInfo) {
            moreInfoList = moreInfo.map(item => {
                    return (                
                        <li>{item}</li>
                    )
                }
            );
        } else {
            moreInfoList = undefined;
        }   


        const script = improveAdsLink(6419);
        const adContainer = "mainospaikka_940_X_400_other_review";
        const altText = this.props.review.director + " : " + this.props.review.title;

        const mainos13 = classnames('mainos', 'paikka_13');     
        const mainos25 = classnames('mainos', 'paikka_25');     
        const mainos26 = classnames('mainos', 'paikka_26');     

        return (
            <div>
                <Card>
                <CardHeader>
                    {headerText}
                </CardHeader>
                <CardBody>
                    <PublishingInfo 
                        authorName={this.props.review.reviewer}
                        date={this.props.review.date}
                    /> 
                    <div className="other-review-detail-top">
                        <div className="other-review-detail-image">
                            <ReactImageFallback
                                src={art}
                                fallbackImage="/images/default_review.png"
                                className="image"
                                alt={altText}/>

                        </div>
                    </div>
       
                    <ShareButtons 
                        width={this.props.containerWidth}                
                        performer={this.props.review.director}
                        message={messageToShare}
                        currentUrl={ownurl} 
                        bandLink={this.props.review.bandlink} 
                        facebookHomeLink={this.props.review.altbandlink}
                        bandLink={this.props.review.link} 
                        facebookLink={undefined}
                        buyLink={this.props.review.buylink}
                        authorName={this.props.review.reviewer}
                        />                                          
                    <div className="other-review-detail-body">
                        {createDangerous(createMarkup(this.props.review.review))}
                    </div> 
                </CardBody>

                </Card>
                <div className="songlist-link">
                <Button className="songlist-button" color="link" onClick={() => this.toggle()}>Lisätiedot&nbsp;<FontAwesome name="fa-arrow-circle-o-down" className="fa-arrow-circle-o-down"/></Button>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            <ul className="more-info-list">
                            {moreInfoList}
                            </ul>
                        </CardBody>
                    </Card> 
                </Collapse>                  
                </div>

                <div key="ma_13" className={mainos13}>
                    <AdPlacer place={13} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>  
                </div>
                <div key="ma_25" className={mainos25}>
                    <AdPlacer place={25} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>
                <div key="ma_26" className={mainos26}>
                    <AdPlacer place={26} pageWidth={containerWidth} pageHeight={400} marginLeft={15} marginRight={15}/>
                </div>

            </div>
        );
    }    
}

OtherReviewsDetailPage.propType = {
    review: PropTypes.object.isRequired,
    location: PropTypes.object,
};

export default Dimensions()(withRouter(OtherReviewsDetailPage));
