'use strict';
import '../../style/header.css';
import React from 'react';
import PropTypes from 'prop-types'
import { Navbar, NavbarToggler, NavbarBrand, Nav, NavLink, NavItem, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, FormGroup, Button, Form, Collapse, Input} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Dimensions from 'react-dimensions';
import R, { compose, equals, indexOf, prop } from 'ramda';
import classnames from 'classnames';
import ReactImageFallback from 'react-image-fallback';
import ImproveAds from './improve_ads';
import Ad from './ad';
import DynamicHeader from 'react-sticky-dynamic-header';
import FontAwesome from 'react-fontawesome';
import { log, improveAdsLink, goTo, parseFromPathName, updateFromEventValue } from '../general';
import AdPlacer from './ad_placer';
import SearchElement from './search_element';



class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleMenuSelect = this.handleMenuSelect.bind(this);

        this.state = {
            isOpen: false,
        }
    }

    
    handleMenuSelect(selectedKey, event) {
        event.target.expanded = false;
    }

    onNavItemClick = () => {
        this.setState({ isOpen: false });
    }

    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    render() {
        const props = this.props;
        const script = improveAdsLink(6415);
        const adContainer =  "mainospaikka_logon_vieressa";
        const plus = <FontAwesome name='plus-square'/>
        const logoSize = (props.pageWidth * 0.23) > 273 ? 273 : (props.pageWidth * 0.23);

        var navbar = undefined;
        var headerName = undefined;
        var headerNameTop = undefined;
        if (props.pageWidth > 768) {
            headerName="header";
            headerNameTop = "header-top";
            navbar = ([
                <Navbar key="menu"  expand="lg" className="main-menu">
                    <NavbarBrand href="/" className="logo-place">
                        <div className="logo">
                            <a href="/">
                                <ReactImageFallback src='/images/basic-logo.png' fallbackImage="/images/default_logo.png" className="logo-pic" />    
                            </a>
                        </div>
        
                    </NavbarBrand>
                    <Nav className="menu-start">
                        <LinkContainer key="news" to="/news/1">
                            <NavItem>
                                <NavLink>Uutiset</NavLink>
                            </NavItem>
                        </LinkContainer>
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav caret>
                                Arvostelut
                            </DropdownToggle>                
                            <DropdownMenu>
                                <LinkContainer key="reviews" to="/reviews/1">
                                    <DropdownItem>Levyt</DropdownItem>
                                </LinkContainer>
                                <LinkContainer key="otherreviews" to="/otherreviews/1">
                                    <DropdownItem>Muut</DropdownItem>
                                </LinkContainer>                                                        
                                <LinkContainer key="demos" to="/demos/1">
                                    <DropdownItem>Demot</DropdownItem>
                                </LinkContainer>
                            </DropdownMenu>    
                        </UncontrolledDropdown>
                        <LinkContainer key="articles" to="/articles/1">
                            <NavItem>
                                <NavLink>Artikkelit</NavLink>
                            </NavItem>
                        </LinkContainer>                    
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav caret>
                                Media
                            </DropdownToggle>                
                            <DropdownMenu>
                                <LinkContainer key="videos" to="/videos/1">
                                    <DropdownItem>Musavideot</DropdownItem>
                                </LinkContainer>
                                <LinkContainer key="imptv" to="/imptv/1">
                                    <DropdownItem>Imperiumi TV</DropdownItem>
                                </LinkContainer>
                                <LinkContainer key="rauta" to="/rauta/1">
                                    <DropdownItem>Rauta</DropdownItem>
                                </LinkContainer>                        
                            </DropdownMenu>      
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav caret>
                                {plus}
                            </DropdownToggle>                
                            <DropdownMenu>
                                <LinkContainer to="/pages/1">
                                    <DropdownItem>Muut jutut</DropdownItem>
                                </LinkContainer>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <NavLink target="_blank" href="https://www.impe.fi/phpbb">          
                                Messulauta
                            </NavLink>
                    </NavItem>
                    </Nav>
                </Navbar>
            ])
        } else {
            headerName = "header-burger";
            headerNameTop = "header-burger-top";
            navbar = ([
                <Navbar key="burger-menu" light expand="lg" className="burger-menu">
                    <NavbarBrand href="/" className="logo-place">
                        <div className="logo">
                            <a href="/">
                                <ReactImageFallback src='/images/basic-logo.png' fallbackImage="/images/default_logo.png" className="logo-pic" />    
                            </a>
                        </div>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle}/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <DropdownItem divider />               
                            <NavItem>
                                <SearchElement onClick={this.onNavItemClick}/>
                            </NavItem>
                            <DropdownItem divider />               
                            <LinkContainer to="/news/1">
                                <NavItem onClick={this.onNavItemClick}>
                                    <NavLink>Uutiset</NavLink>
                                </NavItem>
                            </LinkContainer>
                            <DropdownItem divider />               

                            <div className="nav-header">
                                Arvostelut
                            </div>
                            <LinkContainer to="/reviews/1">
                                <DropdownItem onClick={this.onNavItemClick}>Levyt</DropdownItem>
                            </LinkContainer>
                            <LinkContainer to="/otherreviews/1">
                                <DropdownItem onClick={this.onNavItemClick}>Muut</DropdownItem>
                            </LinkContainer>                                                        
                            <LinkContainer to="/demos/1">
                                <DropdownItem onClick={this.onNavItemClick}>Demot</DropdownItem>
                            </LinkContainer>
                            <DropdownItem divider />               
                            <LinkContainer to="/articles/1">
                                <NavItem onClick={this.onNavItemClick}>
                                    <NavLink>Artikkelit</NavLink>
                                </NavItem>
                            </LinkContainer>                    
                            <DropdownItem divider />               
                            <div className="nav-header">
                                Media
                            </div>
                            <LinkContainer to="/videos/1">
                                <DropdownItem onClick={this.onNavItemClick}>Musavideot</DropdownItem>
                            </LinkContainer>
                            <LinkContainer to="/imptv/1">
                                <DropdownItem onClick={this.onNavItemClick}>Imperiumi TV</DropdownItem>
                            </LinkContainer>
                            <LinkContainer to="/rauta/1">
                                <DropdownItem onClick={this.onNavItemClick}>Rauta</DropdownItem>
                            </LinkContainer>                        
                            <DropdownItem divider />               
                            <LinkContainer to="/pages/1">
                                <NavItem onClick={this.onNavItemClick}>
                                    <NavLink>Muut jutut</NavLink>
                                </NavItem>
                            </LinkContainer>
                            <NavItem>
                                <NavLink target="_blank" href="https://www.impe.fi/phpbb">          
                                    Messulauta
                                </NavLink>
                            </NavItem>
                        </Nav>

                    </Collapse>
                </Navbar>
            ])
        }
        const widthWithoutLogo = props.pageWidth - logoSize;
        return (
            <div>
                <DynamicHeader hasEffect={false} effectDuration={50} useHeadersDifference={true}>
                    <header key="dynamic" className={headerName}>
                        {navbar}
                    </header>
                    <header key="static" className={headerNameTop}>
                        {navbar}            
                </header>     
                </DynamicHeader>        

            </div>
        );
    }
};

Navigation.propTypes = {
    location: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    childrenWidth: PropTypes.number,
};



export default Dimensions()(withRouter(Navigation))
