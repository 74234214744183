'use strict';
import React from 'react';
import PropTypes from 'prop-types'
import R,{ concat, slice } from 'ramda';
import { Form, FormGroup, Label, Input, Col } from 'reactstrap';

import { RotateLoader } from 'react-spinners';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import { queryApiUrl, getYear, getStartSelectedYear, getStartThisYear, httpRequest, log, sortFirstByMatch, sortFirstByLike } from '../general';
import AllGigsPage from '../components/all_gigs_page';

class AllGigs extends React.Component {
    constructor() {
        super();
        const year = getYear();
        this.state = {  gigs: [], year:year, dropdownOpen:false, totalAmount: 0, fetched: false, error: false };
    }

    componentWillMount() {
        this.state.fetched = false;
        this.startSearch(getStartSelectedYear(this.state.year));
        window.scrollTo(0, 0);
    }    

    startSearch(year) {
        const urlPath = queryApiUrl('imp_gigs/rows?sort=date&sort_order=ASC&limit=2000&filters[date][gte]=' + year +  '&in[published]=1');

        const self = this;
        // Make a request for a user with a given ID
        axios.get(urlPath)
        .then(function (response) {
            self.setState({
                gigs: response.data.data,
                totalAmount: response.data.meta.total_entries, 
                fetched: true,
             });
         })
        .catch(function (error) {
            self.setState({gigs:undefined, fetched: true, error: true});
        });    
    }

    
     
    render() {
        const { fetched, gigs, year, dropdownOpen} = this.state;
        if (fetched == false) {
            return (
                <div className="all-gigs-fixed searching">
                    <RotateLoader color="#4C0001" loading={!fetched}/>
                </div>
            );
        } 

        const onChange = (evt) => {
            const selectedYear = evt.target.value;
            this.setState({year: selectedYear, fetched: false});
            this.startSearch(getStartSelectedYear(selectedYear));
        }
    
        const monthDate = new Date();
        const monthAsText = monthDate.toLocaleString('fi-fi', { month: 'long' });

        const giglist = "Keikkalista - " + monthAsText;

        const image = "https://www.impe.fi/images/basic-logo.png";



        return (
            <div className="all-gigs-page">
                <Helmet>
                    <meta property="title" content={giglist}/>
                    <meta property="description" content={giglist}/>
                    <meta property="twitter:title" content={giglist}/>
                    <meta property="twitter:description" content={giglist}/>
                    <meta property="twitter:image" content={image}/>
                    <meta property="og:title" content={giglist}/>
                    <meta property="og:description" content={giglist}/>
                    <meta property="og:image" content={image}/>
                </Helmet>
                <Form >
                    <FormGroup row>
                        <Label sm="2" for="selectYear">Vuosi</Label>
                        <Col sm="10">
                        <Input size="sm" value={year} type="select" name="select" id="selectYear" onChange={onChange}>
                        <option>2020</option>
                        <option>2019</option>
                        <option>2018</option>
                        <option>2017</option>
                        <option>2016</option>
                        <option>2015</option>
                        <option>2014</option>
                        <option>2013</option>
                        <option>2012</option>
                        <option>2011</option>
                        <option>2010</option>
                        <option>2009</option>
                        <option>2008</option>
                        <option>2007</option>
                        <option>2006</option>
                        <option>2005</option>
                        <option>2004</option>
                        <option>2003</option>
                        </Input>
                        </Col>
                    </FormGroup>
                </Form>
                <AllGigsPage 
                    fetched = {fetched}
                    gigs = {gigs}
                    year = {year}
                    />
            </div>
        );
    }
}

AllGigs.propTypes = {
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
};

export default AllGigs;
